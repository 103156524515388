<template>
  <v-app :style="colorVapp">
    <onebox_admin_toolbar
      :quicksearch="true"
      @loadfile="loadfolder()"
      @callstorage="loadstorage()"
      @closeDrag="removeEvent()"
      @openDrag="addEvent()"
    ></onebox_admin_toolbar>
    <v-content>
      <v-card class="elevation-0">
        <v-divider></v-divider>
        <v-overlay :value="processloader" absolute :color="color.BG" dark>
          <v-progress-circular indeterminate size="64" :color="color.theme"></v-progress-circular>
          <span :style="colorProgress">&nbsp; loading</span>
        </v-overlay>
      </v-card>
      <!-- hearder -->
      <v-list nav :color="color.BG">
        <v-list-item class="text-left">
          <v-list-item-avatar class="text-center">
            <v-avatar :color="color.theme" size="35">
              <v-icon dark size="20">mdi-server</v-icon>
            </v-avatar>
          </v-list-item-avatar>
          <v-list-item-content>
            <v-list-item-title>
              <span :style="headerPage">&nbsp;{{ $t("admin.managestorage") }}</span>
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <!-- content -->
      <v-card class="elevation-0" :color="color.BG" id="thiscontainerstorageadmin">
        <v-card-text class="pa-0">
          <v-container fluid>
            <v-layout row wrap justify-center fill-height>
              <!-- <v-flex lg12 xs12 class="ma-2 ml-4 mr-4" v-if="loaddataprogress === true">
                <div class="text-center">
                  <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                </div>
              </v-flex> -->
              <v-flex lg12 class="ma-4">
                <!-- หน้าจอคอม -->
                <!-- <div v-if="loaddataprogress === true">
                   <v-flex lg12 xs12 class="ma-2 ml-4 mr-4" >
                <div class="text-center">
                  <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular>
                </div>
              </v-flex>
                </div> -->
                <div >
                <div  v-if="resolutionScreen >=500">
                <v-layout>
                  <v-flex lg3 class="pr-2 pl-1">
                    <v-card outlined>
                      <v-card-title class="pr-0"
                        ><v-icon class="pr-1" large>mdi-server</v-icon><span>{{ $t('quota_storage.quota_storage')}}</span></v-card-title
                      >
                      <v-card-text class="pb-7">
                        
                        <div class="pt-1 text-h5 ">{{quota_business}}</div>
                      </v-card-text>
                      <br v-if="resolutionScreen > 500 && $t('default') === 'en'" />
                    </v-card>
                  </v-flex>
                  <v-flex lg3 class="pr-2 pl-1">
                    <v-card outlined>
                      <v-card-title><v-icon class="pr-1" large>mdi-server</v-icon><span>{{ $t('quota_storage.quota_usage')}}</span></v-card-title>
                      <v-card-text class="pb-0">
                        <div class="pt-1 text-h5 ">{{usage_business}}</div>
                      </v-card-text>
                      <v-card-actions>                     
                        <v-progress-linear v-if="percent_usage < 60" height="12" :value="percent_usage" color="light-green"><strong  class="caption font-weight-light" >{{ percent_usage }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="percent_usage >= 60 && percent_usage <90"  height="12" :value="percent_usage" color="amber"><strong  class="caption font-weight-light" >{{ percent_usage }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="percent_usage >= 90"  height="12" :value="percent_usage" color="red"><strong  class="caption font-weight-light" >{{ percent_usage }}%</strong></v-progress-linear>
                      </v-card-actions>
                      <br v-if="resolutionScreen > 500 && $t('default') === 'en'" />
                    </v-card></v-flex>
                  <v-flex lg6 class="pr-2 pl-1">
                    <v-card outlined>
                      <v-card-title class="pr-0"
                        ><v-icon class="pr-1" large>mdi-server</v-icon><span>{{ $t('quota_storage.quota_default')}}</span></v-card-title
                      >
                      <v-card-text class="pb-7 mt-n2">
                        <div class="pt-1 text-h5 ">{{default_business.quota_storage === "0.00 KB" ? this.$t("quota_storage.notset") : default_business.quota_storage}}</div>
                      </v-card-text>
                      <v-card-actions class="mt-n11 mb-0">
                        <v-card-subtitle class="pa-0 mb-0 pt-3" style="color:red;">
                          {{$t("quota_storage.messagesetdefault")}}
                        </v-card-subtitle>
                        <v-spacer></v-spacer>
                        <v-btn small dense :color="color.theme" class="white--text" @click="fn_edit_defult_quota()">
                          {{$t("quota_storage.edit")}}
                        </v-btn>              
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                  <!-- <v-flex lg4 class="pr-2 pl-1">
                    <v-card outlined>
                      <v-card-title class="pr-0"
                        ><v-icon class="pr-1" large>mdi-server</v-icon><span>{{ $t('quota_storage.quota_remain')}}</span></v-card-title
                      >
                      <v-card-text class="pb-0"> -->
                        <!-- <v-progress-linear v-if="percentstorage <= 70" :color="color.theme" height="18" :value="percentstorage"><strong  class="caption font-weight-light">{{ Math.ceil(percentstorage) }}%</strong></v-progress-linear>
                <v-progress-linear v-else-if="percentstorage <= 90 && percentstorage > 70" color="amber" height="18" :value="percentstorage"><strong  class="caption font-weight-light">{{ Math.ceil(percentstorage) }}%</strong></v-progress-linear>
                <v-progress-linear v-else-if="percentstorage > 90" color="red" height="18" :value="percentstorage"><strong  class="caption font-weight-light">{{ Math.ceil(percentstorage) }}%</strong></v-progress-linear> -->
                        <!-- <div class="pt-1 text-h5 ">{{remain_business}}</div>
                      </v-card-text>
                      <v-card-actions>
                        <v-progress-linear v-if="percent_remain > 40" height="12" :value="percent_remain" color="light-green"><strong  class="caption font-weight-light" >{{ percent_remain }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="percent_remain <=  40 && percent_remain > 10 "  height="12" :value="percent_remain" color="amber"><strong  class="caption font-weight-light" >{{ percent_remain }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="percent_remain <= 10"  height="12" :value="percent_remain" color="red"><strong  class="caption font-weight-light" >{{ percent_remain }}%</strong></v-progress-linear>
                      </v-card-actions>
                    </v-card></v-flex
                  > -->
                </v-layout>
                </div>
                <!-- หน้าจอโทรศัพท์ -->
                <div v-else>       
                  <v-flex  class="pr-2 pl-1">
                    <v-card outlined>
                      <v-card-title class="pr-0"
                        ><v-icon class="pr-1" large>mdi-server</v-icon><span>{{ $t('quota_storage.quota_storage')}}</span></v-card-title
                      >
                      <v-card-text>
                        <div class="pt-1 text-h5 ">{{quota_business}}</div>
                      </v-card-text>
                    </v-card>
                  </v-flex>
                  <v-flex  class="pr-2 pl-1">
                    <v-card outlined>
                      <v-card-title><v-icon class="pr-1" large>mdi-server</v-icon><span>{{ $t('quota_storage.quota_usage')}}</span></v-card-title>
                      <v-card-text>
                        <div class="pt-1 text-h5 ">{{usage_business}}</div>
                      </v-card-text>
                      <v-card-actions>                     
                        <v-progress-linear v-if="percent_usage < 60" height="12" :value="percent_usage" color="light-green"><strong  class="caption font-weight-light" >{{ percent_usage }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="percent_usage >= 60 && percent_usage <90"  height="12" :value="percent_usage" color="amber"><strong  class="caption font-weight-light" >{{ percent_usage }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="percent_usage >= 90"  height="12" :value="percent_usage" color="red"><strong  class="caption font-weight-light" >{{ percent_usage }}%</strong></v-progress-linear>
                      </v-card-actions>
                    </v-card></v-flex>
                  <v-flex class="pr-2 pl-1">
                    <v-card outlined>
                      <v-card-title class="pr-0"
                        ><v-icon class="pr-1" large>mdi-server</v-icon><span>{{ $t('quota_storage.quota_default')}}</span></v-card-title
                      >
                      <v-card-text class="pb-7">
                        <div class="pt-1 text-h5 ">{{default_business.quota_storage === "0.00 KB" ? this.$t("quota_storage.notset") : default_business.quota_storage}}</div>
                      </v-card-text>
                      <v-card-actions class="mt-n9"> 
                        <v-card-subtitle class="pa-0 mb-0 pt-3 mx-2" style="color:red;">
                          {{$t("quota_storage.messagesetdefault")}}
                        </v-card-subtitle>
                        <v-spacer></v-spacer>
                        <v-btn small dense :color="color.theme" class="white--text" @click="fn_edit_defult_quota()">
                          {{$t("quota_storage.edit")}}
                        </v-btn>              
                      </v-card-actions>
                    </v-card>
                  </v-flex>
                  <!-- <v-flex  class="pr-2 pl-1">
                    <v-card outlined>
                      <v-card-title class="pr-0"
                        ><v-icon class="pr-1" large>mdi-server</v-icon><span>{{ $t('quota_storage.quota_remain')}}</span></v-card-title>
                      <v-card-text>
                        <div class="pt-1 text-h5 ">{{remain_business}}</div>
                      </v-card-text>
                      <v-card-actions>
                        <v-progress-linear v-if="percent_remain > 40" height="12" :value="percent_remain" color="light-green"><strong  class="caption font-weight-light" >{{ percent_remain }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="percent_remain <= 40 && percent_remain > 10"  height="12" :value="percent_remain" color="amber"><strong  class="caption font-weight-light" >{{ percent_remain }}%</strong></v-progress-linear>
                        <v-progress-linear v-else-if="percent_remain <= 10"  height="12" :value="percent_remain" color="red"><strong  class="caption font-weight-light" >{{ percent_remain }}%</strong></v-progress-linear>
                      </v-card-actions>
                    </v-card>
                    </v-flex> -->
               
                </div>
                </div>
                <br />
                <v-layout >
                <!-- <v-flex lg6 v-if="statusmutiplesetting === false">
                  <v-btn @click="statusmutiplesetting = !statusmutiplesetting">multiple setting</v-btn>
                </v-flex>
                <v-flex lg6 v-if="statusmutiplesetting === true">
                  <v-btn @click="openstoragemulti('MU1')" fab small class="mr-2 ml-2">1</v-btn>
                   <v-btn @click="openstoragemulti('MU2')" fab small class="mr-2">2</v-btn>
                   <v-btn @click="openstoragemulti('MU3')" fab small class="mr-2">3</v-btn>
                   <v-btn @click="statusmutiplesetting = false" fab small>X</v-btn>
                </v-flex> -->
                <!-- <v-flex  class="text-right"> -->
                 <!-- <v-chip color="blue lighten-4" class="ma-2 mr-0"><v-icon class="pr-2" small>mdi-domain</v-icon>{{ $t("quota_storage.quota_DE") }} {{total_DE_quota}}</v-chip> -->
                 <!-- <v-chip color="cyan lighten-4" class="ma-2 ml-1"><v-icon class="pr-2" small>mdi-account</v-icon>{{ $t("quota_storage.quota_EM") }} {{total_EM_quota}}</v-chip> -->
                <!-- </v-flex> -->
                </v-layout>
                <div v-if="loaddataprogressEM === true" >
                   <v-flex lg12 xs12 class="ma-2 ml-4 mr-4" >
                        <div class="text-center">
                            <!-- <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular> -->
                            <v-skeleton-loader
                              class="mx-auto"
                              type="table"
                          ></v-skeleton-loader>
                          </div>
                   </v-flex>
                </div>
                <div v-else>
                <!-- <v-tabs v-model="tab" :color="color.theme"> -->
                  <!-- <v-tabs-slider :slider-color="color.theme" ></v-tabs-slider> -->
                  <!-- <v-tab :color="color.theme" @click="fn_loadstorage_department()">
                    {{ $t("admin.department") }}
                  </v-tab> -->
                  <!-- <v-tab :color="color.theme">
                    {{ $t("admin.employee") }}
                  </v-tab>
                </v-tabs> -->
                <!-- <v-tabs-items v-model="tab" > -->
                  <!-- แผนก -->
                  <!-- <v-tab-item> -->
                      <!-- <div v-if="loaddataprogressDE === true">
                        <v-flex lg12 xs12 class="ma-2 ml-4 mr-4" >
                        <div class="text-center"> -->
                            <!-- <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular> -->
                            <!-- <v-skeleton-loader
                              class="mx-auto"
                              type="table"
                          ></v-skeleton-loader>
                          </div>
                        </v-flex>
                      </div> -->
                    <!-- <div v-else>
                    <div v-if="resolutionScreen >=500">
                     
                    <v-data-table
                      class="elevation-0"
                      :headers="headerdepartment"
                      :items="data_department"
                      :hide-default-footer="true"
                      @page-count="pageCount = $event"
                      :show-select="statusmutiplesetting"
                      item-selected
                      :single-select="false"
                      :page.sync="pageD"
                      :items-per-page="sizeD"
                    > -->
                      <!-- <template v-slot:[`header.department_name`]="{ header }"
                        ><span :style="headerTable">{{ $t(header.text) }}</span></template
                      >
                      <template v-slot:[`header.storage_quota`]="{ header }"
                        ><span :style="headerTable">{{ $t(header.text) }}</span></template
                      >
                      <template v-slot:[`header.storage_use`]="{ header }"
                        ><span :style="headerTable">{{ $t(header.text) }}</span></template
                      >
                      <template v-slot:[`header.storage_remain`]="{ header }"
                        ><span :style="headerTable">{{ $t(header.text) }}</span></template
                      >
                      <template v-slot:[`header.option`]="{ header }"
                        ><span :style="headerTable">{{ $t(header.text) }}</span></template
                      >
                      <template v-slot:item="props">
                        <tr>
                          <td class="text-center" v-show="statusmutiplesetting">
                              <v-checkbox
                                v-model="datamultiplesetting"
                                :value="props.item"
                              /> -->
                            <!-- </td>
                          <td width="45%">{{ props.item.department_name }}</td>
                          <td width="15%">{{ props.item.quota_storage }}</td>
                          <td width="15%">{{ props.item.used_storage }}</td>
                          <td width="15%">{{ props.item.remain_storage }}</td>
                          <td width="10%">
                            <v-btn class="elevation-0" small text @click="openstorage(props.item,'de')" fab><v-icon>settings</v-icon></v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    </div>
                    <div v-else>
                      <v-list two-line>
                        <v-list-item
                        v-for="(item, i) in data_department"
                        :key="i"
                        >
                        <v-list-item-content>
                          <v-list-item-title v-text="item.department_name"></v-list-item-title>
                          <v-list-item-subtitle v-text="$t('admin.storage')+': '+item.quota_storage"></v-list-item-subtitle>
                          </v-list-item-content>
                        <v-list-item-action><v-btn class="elevation-0" small text @click="openstorage(item,'de')" fab><v-icon>settings</v-icon></v-btn></v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </div>
                    
                    <br />
                  <v-layout row wrap justify-center>
                    <v-flex xs11 lg11 v-if="countD > 0">
                      <v-pagination
                        :total-visible="10"
                        v-model="pageD"
                        :length="pageCountDE || 0"
                        :color="color.theme"
                        @input="changepage('d')"
                      ></v-pagination>
                    </v-flex>
                  </v-layout>
                  </div> -->
                  <!-- </v-tab-item> -->
                  <!-- พนักงาน -->
                  <!-- <v-tab-item> -->
                <v-flex lg12 class="ma-4" >
                  <v-layout row wrap >
                    <v-flex lg3 md12 xs12 class="mb-2">
                      <!-- <v-select
                        class="pl-1"
                        v-model="selectdepartment"
                        outlined
                        dense
                        :color="color.theme"                  
                        :items="items_department"
                         item-text="department_name"
                         item-value="department_id"
                        :label="$t('admin.filterdepartment')"
                        hide-details
                        @change="fn_loadstorage_employee()"
                      ></v-select> -->
                      <v-select
                        class="pl-1"
                        v-model="selectdepartment"
                        outlined
                        dense
                        :color="color.theme"                  
                        :items="items_department"
                        :item-text="$t('default') === 'th' ? 'department_name_th' : 'department_name_eng'"
                         item-value="department_id"
                        :label="$t('admin.filterdepartment')"
                        hide-details
                        @change="fn_loadstorage_employee()"
                      >
                        <template v-slot:item="{ item }">
                          <div class="text-center">
                            <span style="font-size: 17px;" v-if="item.department_name === 'ทั้งหมด'">{{ $t("quota_storage.alldepartment") }}</span>
                            <span style="font-size: 17px;" v-else>{{ item.department_name }}</span>
                          </div>
                        </template>
                      </v-select>
                    </v-flex>
                    <br v-if="resolutionScreen < 400" />
                    <v-flex lg2 sm10 xs9>
                      <v-text-field
                        class="pl-1"
                        :label="$t('admin.search')"
                        v-model="search"
                        clearable
                        outlined
                        dense
                        :color="color.theme"
                        append-icon="search"
                        @click:clear="search = '',fn_loadstorage_employee()"
                        hide-details
                      ></v-text-field>
                    </v-flex>
                    <v-flex lg1 sm1 xs3 class="pl-1">
                      <v-btn :color="color.theme" :dark="color.darkTheme" @click="fn_loadstorage_employee(), (clicksearch = true)">{{
                        $t("admin.search")
                      }}</v-btn>
                    </v-flex>
                  </v-layout>
                  <v-layout row wrap>
                    <v-flex xs6 lg4>
                      <div
                        v-if="statusmutiplesetting === false"
                        class="pt-2 hidden-sm-and-down"
                      >
                        <v-btn
                          @mouseover="show_menu = true"
                          @mouseleave="show_menu = false"
                          :color="color.theme"
                          :dark="color.darkTheme"
                          rounded
                          class="ma-1"
                          @click="
                            (statusmutiplesetting = true), (show_menu = false)
                          "
                          fab
                          small
                        >
                          <v-icon>mdi-inbox-multiple</v-icon>&nbsp;
                          <!-- {{ $t('myinboxPage.multipledownload')}} -->
                          <v-badge
                            v-if="$t('default') === 'th'"
                            style="z-index:10000;bottom:-45px;left:-50px;"
                            :value="show_menu"
                            :content="$t('balloon.managestorage')"
                            transition="slide-y-transition"
                          ></v-badge>
                          <v-badge
                            v-else
                            style="z-index:10000;bottom:-45px;left:-50px;"
                            :value="show_menu"
                            :content="$t('balloon.managestorage')"
                            transition="slide-y-transition"
                          ></v-badge>
                        </v-btn>
                      </div>
                      <div v-else class="pt-2">
                        <v-btn
                          @mouseover="show_setstorage = true"
                          @mouseleave="show_setstorage = false"
                          fab
                          small
                          class="ma-1"
                          @click="openstorage('','em','SELECT')"
                        >
                          <v-icon color="black">settings</v-icon>
                          <v-badge
                            v-if="$t('default') === 'th'"
                            style="z-index:10000;bottom:-45px;left:-50px;"
                            :value="show_setstorage"
                            :content="$t('balloon.selectsettingmanagestorage')"
                            transition="slide-y-transition"
                          ></v-badge>
                          <v-badge
                            v-else
                            style="z-index:10000;bottom:-45px;left:-50px;"
                            :value="show_setstorage"
                            :content="$t('balloon.selectsettingmanagestorage')"
                            transition="slide-y-transition"
                          ></v-badge>
                        </v-btn>
                        <v-btn
                          @mouseover="show_clear = true"
                          @mouseleave="show_clear = false"
                          fab
                          small
                          :color="color.theme"
                          :dark="color.darkTheme"
                          class="ma-1 elevation-3"
                          @click="
                            (statusmutiplesetting = false),
                            (datamultiplesetting = []),
                              (show_clear = false)
                          "
                        >
                          <v-icon>clear</v-icon>
                          <v-badge
                            v-if="$t('default') === 'th'"
                            style="z-index:10000;bottom:-45px;left:-20px;"
                            :value="show_clear"
                            :content="$t('balloon.clear')"
                            transition="slide-y-transition"
                          ></v-badge>
                          <v-badge
                            v-else
                            style="z-index:10000;bottom:-45px;left:-30px;"
                            :value="show_clear"
                            :content="$t('balloon.clear')"
                            transition="slide-y-transition"
                          ></v-badge>
                        </v-btn>
                      </div>
                    </v-flex>
                    <v-flex xs6 lg8 class="text-right">
                      <!-- ตั้งค่าพื้นที่ทั้งหมด -->
                      <v-btn
                        small
                        :color="color.theme"
                        class="ma-1 mt-6"
                        :disabled="loaddataprogress"
                        @click="openstorage('','em','ALL')"
                        dark
                      >
                        <span>{{$t("balloon.allsettingmanagestorage")}}</span>
                      </v-btn>
                    </v-flex>
                  </v-layout>
                </v-flex>
                    <div v-if="loaddataprogressEM === true">
                        <v-flex lg12 xs12 class="ma-2 ml-4 mr-4" >
                      <div class="text-center">
                            <!-- <v-progress-circular :size="50" :color="color.theme" indeterminate></v-progress-circular> -->
                            <v-skeleton-loader
                              class="mx-auto"
                              type="table"
                          ></v-skeleton-loader>
                          </div>
                        </v-flex>
                      </div>
                      <div v-else>
                    <div  v-if="resolutionScreen >=500">
                    <v-data-table
                      v-model="datamultiplesetting"
                      class="elevation-0"
                      :headers="headerstaff"
                      :items="data_employee"             
                      :show-select="statusmutiplesetting"
                      item-selected
                      item-key="user_id"
                      :single-select="false"
                      :page.sync="pageE"
                      :items-per-page="sizeE"
                      :hide-default-footer="true"
                      @page-count="pageCount = $event"
                    >
                      <template v-slot:[`header.staff_name`]="{ header }"
                        ><span :style="headerTable">{{ $t(header.text) }}</span></template
                      >
                      <template v-slot:[`header.storage_quota`]="{ header }"
                        ><span :style="headerTable">{{ $t(header.text) }}</span></template
                      >
                      <template v-slot:[`header.storage_use`]="{ header }"
                        ><span :style="headerTable">{{ $t(header.text) }}</span></template
                      >
                      <template v-slot:[`header.storage_remain`]="{ header }"
                        ><span :style="headerTable">{{ $t(header.text) }}</span></template
                      >
                      <template v-slot:[`header.option`]="{ header }"
                        ><span :style="headerTable">{{ $t(header.text) }}</span></template
                      >
                      <template v-slot:item="props">
                        <tr>
                          <td class="text-center" v-show="statusmutiplesetting">
                              <v-checkbox
                                v-model="datamultiplesetting"
                                :value="props.item"
                              />
                            </td>
                          <td width="45%" v-if="$t('default') === 'en'">{{ props.item.user_name_en }}</td>
                          <td width="45%" v-else>{{ props.item.user_name_th }}</td>
                          <td width="15%">
                            {{ props.item.quota_storage === "Not set" ? $t("quota_storage.notset") : props.item.quota_storage }}
                          </td>
                          <td width="15%">{{ props.item.used_storage }}</td>
                          <td width="15%">{{ props.item.remain_storage}}</td>
                          <td width="10%">
                            <v-btn class="elevation-0" small text @click="openstorage(props.item,'em', 'ONE')" fab><v-icon>settings</v-icon></v-btn>
                          </td>
                        </tr>
                      </template>
                    </v-data-table>
                    </div>
                    <div v-else>
                       <v-list two-line>
                        <v-list-item
                        v-for="(item, i) in data_employee"
                        :key="i"
                        >
                        <v-list-item-content v-if="$t('default') === 'en'">
                          <v-list-item-title v-text="item.user_name_en"></v-list-item-title>
                          <v-list-item-subtitle v-text="$t('admin.storage')+': '+item.quota_storage"></v-list-item-subtitle>
                          </v-list-item-content>
                          <v-list-item-content v-else>
                          <v-list-item-title v-text="item.user_name_th"></v-list-item-title>
                          <v-list-item-subtitle v-text="$t('admin.storage')+': '+item.quota_storage"></v-list-item-subtitle>
                          </v-list-item-content>
                        <v-list-item-action><v-btn class="elevation-0" small text @click="openstorage(item,'em','ONE')" fab><v-icon>settings</v-icon></v-btn></v-list-item-action>
                        </v-list-item>
                      </v-list>
                    </div>
                    <br />
                  <v-layout row wrap justify-center>
                    <v-flex xs11 lg11 v-if="countE > 0">
                      <v-pagination
                        :total-visible="10"
                        v-model="pageE"
                        :length="pageCountEM || 0"
                        :color="color.theme"
                        @input="changepage('e')"
                      ></v-pagination>
                    </v-flex>
                  </v-layout>
                  </div>
                  <!-- </v-tab-item>
                </v-tabs-items> -->
                </div>
              </v-flex>
            </v-layout></v-container
          ></v-card-text>
        </v-card>
      <managestorage
        :show="openmanagestorage"
        @close="openmanagestorage = false"
        @closemanagestorage="openmanagestorage = false,fn_loadstorage_business(), datamultiplesetting = []"
        :datastorage="datastorage"
        :remain_business="remain_business"
        :percent_remain="percent_remain"
        :type="type"
        :datamultiplesetting="datamultiplesetting"
        :statusmulti="statusmulti"
      ></managestorage>
      <managedefaultstorage
        :show="openmanagedefaultstorage"
        @close="openmanagedefaultstorage = false"
        @closereload="openmanagedefaultstorage = false, fn_load_default_storage()"
        :default_business="default_business"
      ></managedefaultstorage>
      <!-- <managestoragemulti
        :show="openmanagestoragemulti"
        @closemanagestorage="openmanagestoragemulti = false,fn_loadstorage_business()"
        :datamultiplesetting="datamultiplesetting"
        :remain_business="remain_business"
        :percent_remain="percent_remain"
        :type="type_mu"
      
      ></managestoragemulti> -->
    </v-content>
  </v-app>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.js";
import { mapState, mapGetters } from "vuex";
import "vue-full-screen-file-drop/dist/vue-full-screen-file-drop.css";
import gbfGenerate from "../globalFunctions/generateAuthorize";
import convertByte from "../globalFunctions/sizeDataConverter"
import onebox_admin_toolbar from "../components/layout/layout-admin-toolbar";
const adminrightclick = () => import("../components/contextmenu/adminrightclick");
const managestorage = () => import("../components/optional/dialog-managestorage");
const managestoragemulti = () => import("../components/optional/dialog-managestorage-multi");
const managestoragestaff = () => import("../components/optional/dialog-managestoragestaff");
const managedefaultstorage = () => import("../components/optional/dialog-managedefaultstorage");
const Toast = Swal.mixin({
  toast: true,
  position: "top-end",
  showConfirmButton: false,
  timer: 3000,
});

export default {
  components: {
    onebox_admin_toolbar,
    adminrightclick,
    managestorage,
    managestoragemulti,
    managedefaultstorage
  },
  data: function() {
    return {
      countD:0,
      countE:0,
      loaddataprogressDE:true,
      loaddataprogressEM:true,
      openmanagestoragemulti:false,
      type_mu:"",
      datamultiplesetting:[],
      statusmutiplesetting:false,
      show_menu: false,
      show_setstorage: false,
      show_clear: false,
      statusmulti: "",
      total_DE_quota:"",
      total_EM_quota:"",
      pageE:1,
      sizeE:30,
      pageD:1,
      sizeD:30,
      percent_usage:0,
      percent_remain:0,
      type:"",
      quota_business:"",
      usage_business:"",
      remain_business:"",
      default_business: {},
      data_department:[],
      data_employee:[],
      percentstorage: 0,
      tab: null,
      openmanagestorage: false,
      openmanagestoragestaff: false,
      openmanagedefaultstorage: false,
      showadminMenu: false,
      storagedata: [],
      storagedatadepartment: [],
      datastorage: {},
      search: "",
      remainstorage: "",
      usedstorage: "",
      totalstorage: "",
      selectdepartment: "",
      selectset: "employee",
      items_department:[],
      loaddataprogress: false,
      search:"",
      size: 50,
      page: 1,
      departments: [
        {
          name: "HR",
          storage_quota: "50 GB",
          storage_use: "1GB",
          storage_remain: "49 GB",
        },
      ],
      staff: [
        {
          name: "Maprang",
          storage_quota: "50 GB",
          storage_use: "1GB",
          storage_remain: "49 GB",
        },
      ],
      headerstaff: [
        {
          text: "admin.name",
          align: "",
          value: "staff_name",
          width: "45%",
          sortable: false,
        },
        {
          text: "admin.storage",
          align: "",
          value: "storage_quota",
          width: "15%",
          sortable: false,
        },
        {
          text: "admin.use_storage",
          align: "",
          value: "storage_use",
          width: "15%",
          sortable: false,
        },
        {
          text: "admin.remain_storage",
          align: "",
          value: "storage_remain",
          width: "15%",
          sortable: false,
        },
        {
          text: "admin.option",
          align: "",
          value: "option",
          width: "10%",
          sortable: false,
        },
      ],
      headerdepartment: [
        {
          text: "admin.department",
          align: "",
          value: "department_name",
          width: "45%",
          sortable: false,
        },
        {
          text: "admin.storage",
          align: "",
          value: "storage_quota",
          width: "15%",
          sortable: false,
        },
        {
          text: "admin.use_storage",
          align: "",
          value: "storage_use",
          width: "15%",
          sortable: false,
        },
        {
          text: "admin.remain_storage",
          align: "",
          value: "storage_remain",
          width: "15%",
          sortable: false,
        },
        {
          text: "admin.option",
          align: "",
          value: "option",
          width: "10%",
          sortable: false,
        },
      ],
    };
  },
  computed: {
    ...mapState(["username", "authorize", "account_active", "service", "color"]),
    ...mapState({ processloader: "loading" }),
    ...mapGetters([
      "dataUsername",
      "dataAuthorize",
      "dataAccountActive",
      "dataAccesstoken",
      "dataBusinessProfile",
      "dataCitizenProfile",
      "dataDepartmentAccessId",
      "dataAccountId",
      "dataStorageUsage",
      "dataStorageMax",
    ]),
    resolutionScreen() {
      switch (this.$vuetify.breakpoint.name) {
        case "xs":
          return 220;
        case "sm":
          return 400;
        case "md":
          return 500;
        case "lg":
          return 600;
        case "xl":
          return 800;
      }
    },
    pageCountDE: {
      get() {
        let l = this.countD;
        let s = this.sizeD;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    pageCountEM: {
      get() {
        let l = this.countE;
        let s = this.sizeE;
        return Math.ceil(l / s);
      },
      set(newName) {
        return newName;
      },
    },
    paginatedDataDE() {
      const start = (this.pageD - 1) * this.sizeD;
      const end = start + this.sizeD;
      return this.countD.slice(start, start + this.sizeD);
    },
     paginatedDataEM() {
      const start = (this.pageE - 1) * this.sizeE;
      const end = start + this.sizeE;
      return this.countE.slice(start, start + this.sizeE);
    },
    showdatafilter() {
      // console.log(search);
      return this.rootfile.filter((item) => {
        return item.file_name.toLowerCase().indexOf(this.search.toLowerCase()) > -1;
      });
    },
    headerTable() {
      return "color:" + this.color.theme + ";" + "font-weight: 600; font-size:15px;";
    },
    headerPage() {
      // console.log(this.color.theme);

      return "color:" + this.color.theme + ";" + "font-size: 18px; font-weight: 600;";
    },
    colorVapp() {
      return "background:" + this.color.BG + ";";
    },
    colorSort() {
      return "color:" + this.color.alertText + ";";
    },
    colorProgress() {
      return "color:" + this.color.theme + ";";
    },
  },
  methods: {
    changepage(parameter) {
      if(parameter === 'd'){
       this.loaddataprogressDE = true;
       this.fn_loadstorage_department()
      }else{
        this.fn_loadstorage_employee()
        this.loaddataprogressEM = true;
      }
      
    },
    openstorage(parameter, type, statusmulti) {
      if (statusmulti === "SELECT" && this.datamultiplesetting.length === 0) {
        Toast.fire({
          icon: "warning",
          title: this.$t("quota_storage.pleaseselectemployee"),
        }); 
      } else {
        this.openmanagestorage = true;
        this.datastorage = parameter;
        this.type = type;
        this.statusmulti = statusmulti;
      }
    },
    openstoragemulti(type) {
      this.openmanagestoragemulti = true;
     
      this.type_mu = type
    },
    async fn_load_department(){
      var payload = {
        account_admin:this.dataAccountId,
        business_id:this.dataAccountActive.business_info.business_id,
      }
      let auth = await gbfGenerate.generateToken();
       await this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/admin/get_department_for_limit_storage", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          console.log(response);
          if(response.data.status === "OK"){
                
                this.items_department.push({
                  department_id: "",
                  department_name:"ทั้งหมด",
                  department_name_th: "ทั้งหมด",
                  department_name_eng: "All",
                })
                for(let i = 0 ; i< response.data.result.length;i++){
                  let item_department = response.data.result[i];
                  item_department.department_name_th = item_department.department_name
                  item_department.department_name_eng = item_department.department_name
                  this.items_department.push(item_department)
                }
                console.log(this.items_department);
            }else{
               Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage,
            }); 
            }
        })
    },
    async fn_get_employee_department(){
      let cal_limit = this.sizeE;
      let cal_offset = this.sizeE * (this.pageE - 1);
      var payload = {
        account_admin:this.dataAccountId,
        business_id:this.dataAccountActive.business_info.business_id,
        type: "employee",
        limit: cal_limit,
        offset: cal_offset,
        employee_name:this.search,
        department_id:this.selectdepartment
      }
      let auth = await gbfGenerate.generateToken();
       await this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "//api/admin/get_employees_and_department", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          if (response.data.status === "OK") {
             this.data_employee=[];
             this.loaddataprogressEM = false;
            for(let i = 0 ; i< response.data.result.length;i++){
              let data = {};
              data["account_user"] = response.data.result[i]["account_user"];
              data["admin_business_status"] = response.data.result[i]["admin_business_status"];
              data["remain_storage"] = response.data.result[i]["remain_storage"];
              data["quota_storage"] = response.data.result[i]["storage_account"];
              data["quota_storage_num"] = this.convertnumber(response.data.result[i]["storage_account"]);
              data["quota_storage_unit"] = this.convertbit(response.data.result[i]["storage_account"]);
              data["used_storage"] = response.data.result[i]["used_storage"];
              data["user_name_en"] = response.data.result[i]["user_name_en"];
              data["user_name_th"] = response.data.result[i]["user_name_th"];
              data["user_id"] = response.data.result[i]["user_id"];
              this.data_employee.push(data);
            }
            //console.log(this.data_employee);
          }else{
           this.loaddataprogressEM = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage
            });
          }
        })
        .catch((error) => {
           this.loaddataprogressEM = false;
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: error,
          });
          
        });
    },
    async fn_loadstorage_business(){
      this.loaddataprogress = true;
      var payload = {
         account_admin: this.dataAccountId,
         business_id: this.dataAccountActive.business_info.business_id,
      }
      let auth = await gbfGenerate.generateToken();
       await this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/admin/get_storage_business", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          console.log("file1", response);
       
          if (response.data.status === "OK") {
            this.loaddataprogress = false;
            this.quota_business = response.data.result["storage_business"];
            this.usage_business = response.data.result["used_storage"];
            this.remain_business = response.data.result["remain_storage"];
            this.total_DE_quota = response.data.result["manage_storage_department"];
            this.total_EM_quota = response.data.result["manage_storage_account"];
            let _usage = Math.round((convertByte.convertStorageToByte(this.usage_business)/convertByte.convertStorageToByte(this.quota_business))*100);
            let _remain = 100-_usage
            console.log(_usage,_remain);
            this.percent_usage = _usage > 100 ? 100 :_usage;
            this.percent_remain = _remain < 0 ? 0 : _remain;
            console.log("percent",this.percent_usage,this.percent_remain);
          }else{
            this.loaddataprogress = false;
            this.quota_business = "-"
            this.usage_business = "-"
            this.remain_business = "-"
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage
              
            });
          }
        })
        .catch((error) => {
           this.loaddataprogress = false;
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: error,
          });
          
        });

        this.fn_loadstorage_employee();
    },
    async fn_loadstorage_department(){    
      this.loaddataprogressDE = true;
      let cal_limit = this.sizeD;
      let cal_offset = this.sizeD * (this.pageD - 1);
      var payload = {
         account_admin: this.dataAccountId,
         business_id: this.dataAccountActive.business_info.business_id, 
         type:"department",
         limit:cal_limit,
         offset:cal_offset
      }
       let auth = await gbfGenerate.generateToken();
       await this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/admin/get_employees_and_department", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          console.log("file", response);
          
          if (response.data.status === "OK") {
             this.data_department=[];
            this.loaddataprogressDE = false;
           this.countD = response.data.count;
            for(let i = 0 ; i< response.data.result.length;i++){
              let datadepart = {};
              datadepart["department_name"] = response.data.result[i]["department_name"];
              datadepart["department_id"] = response.data.result[i]["department_id"];
              datadepart["my_folder_id"] = response.data.result[i]["my_folder_id"];
              datadepart["remain_storage"] = response.data.result[i]["remain_storage"];
              datadepart["quota_storage"] = response.data.result[i]["storage_department"];
              datadepart["quota_storage_num"] = this.convertnumber(response.data.result[i]["storage_department"]);
              datadepart["quota_storage_unit"] = this.convertbit(response.data.result[i]["storage_department"]);
              datadepart["used_storage"] = response.data.result[i]["used_storage"];
              this.data_department.push(datadepart);
            }
            console.log(this.data_department);
          }else{
             this.loaddataprogressDE = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage
            });
          }
        })
        .catch((error) => {
           this.loaddataprogressDE = false;
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: error,
          });
          
        });
     // this.fn_loadstorage_employee();
    },
    async fn_loadstorage_employee(){    
      this.loaddataprogressEM = true;
      let cal_limit = this.sizeE;
      let cal_offset = this.sizeE * (this.pageE - 1);
      var payload = {
         account_admin: this.dataAccountId,
         business_id: this.dataAccountActive.business_info.business_id,
         type:"employee",
         limit:cal_limit,
         offset:cal_offset,
         employee_name:this.search || "",
        department_id:this.selectdepartment || ""
      }
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/admin/get_employees_and_department", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          console.log("file", response,this.countE );
           this.countE = response.data.count;
          if (response.data.status === "OK") {
             this.data_employee=[];
             this.loaddataprogressEM = false;
            for(let i = 0 ; i< response.data.result.length;i++){
              let data = {};
              data["account_user"] = response.data.result[i]["account_user"];
              data["admin_business_status"] = response.data.result[i]["admin_business_status"];
              data["remain_storage"] = response.data.result[i]["remain_storage"];
              data["quota_storage"] = response.data.result[i]["storage_account"];
              data["quota_storage_num"] = this.convertnumber(response.data.result[i]["storage_account"]);
              data["quota_storage_unit"] = this.convertbit(response.data.result[i]["storage_account"]);
              data["used_storage"] = response.data.result[i]["used_storage"];
              data["user_name_en"] = response.data.result[i]["user_name_en"];
              data["user_name_th"] = response.data.result[i]["user_name_th"];
              data["user_id"] = response.data.result[i]["user_id"];
              this.data_employee.push(data);
            }
            //console.log(this.data_employee);
          }else{
           this.loaddataprogressEM = false;
            Toast.fire({
              icon: "error",
              title: response.data.errorCode + ': ' + response.data.errorMessage
            });
          }
        })
        .catch((error) => {
           this.loaddataprogressEM = false;
          // console.log(error);
          Toast.fire({
            icon: "error",
            title: error,
          });
          
        });
    },
    convertnumber(parameter){
      let res_ = parameter.split(" ");    
      let storage = parseFloat(res_[0]);
      return storage
    },
    convertbit(parameter){
      let res_ = parameter.split(" ");
      let storage = res_[1];
      
      return storage
      
    },
    async fn_load_default_storage() {
      let payload = {
        account_admin: this.dataAccountId,
        business_id: this.dataAccountActive.business_info.business_id,
        convert_storage: "True"
      }
      let auth = await gbfGenerate.generateToken();
      await this.axios
        .post(process.env.VUE_APP_SERVICE_ADMIN_BOX + "/api/admin/get_quota_storage_business", payload,{headers: { Authorization: auth.code },})
        .then((response) => {
          console.log("response", response);
          if (response.data.status === "OK") {
            this.default_business = response.data.data[0];
            this.default_business.quota_storage = response.data.data[0].quota_storage;
          } else {
            Toast.fire({
              icon: "error",
              title: "ไม่สามารถแสดงข้อมูลโควต้าพื้นที่จัดเก็บเริ่มต้นได้"
            });
          }
        })
        .catch((error) => {
          Toast.fire({
            icon: "error",
            title: error,
          });
          
        });
    },
    fn_edit_defult_quota() {
      this.openmanagedefaultstorage = true;
    }
  },
  mounted() {
    this.fn_loadstorage_business();
    this.fn_load_department();
    this.fn_load_default_storage();
  
  },
};
</script>
<style>
#thiscontainerstorageadmin {
  height: calc(101vh - 180px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  -moz-overflow-scrolling: touch;
  -ms-overflow-scrolling: touch;
}
</style>
